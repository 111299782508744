<template>
  <div class="page">

    <div class="box1">
      <div></div>
      <img src="../assets/gis/caso.png"
           alt="">
    </div>

    <div class="box2">
      <div class="box3">
        <div class="from">
          <div class="input1">
            <label for="username"></label>
            <p></p>
            <div>
              <input type="text"
                     v-model="username"
                     placeholder="用户名/邮箱"
                     id="username">
            </div>
          </div>
          <div class="input2">
            <label for="password"></label>
            <p></p>
            <div>
              <input type="password"
                     v-model="password"
                     placeholder="密码"
                     id="password">
            </div>
          </div>
          <div class="btn"
               @click="login">登 录</div>
          <p>没有账号? 请点击 &nbsp;<a href="#"
               @click="signup">注册</a>; 忘记密码请点击 &nbsp;<a @click="resetPassword">找回</a></p>
        </div>
      </div>
    </div>

    <!-- <div class="panel">
      <h3>欢迎登录</h3>
      <div class="line">
        <label for="username">账号</label>
        <input type="text"
               v-model="username"
               placeholder="用户名/邮箱"
               id="username" />
      </div>
      <div class="line">
        <label for="password">密码</label>
        <input type="password"
               v-model="password"
               placeholder="密码"
               id="password" />
      </div>
      <div class="line">
        <div class="btn"
             @click="login">登录</div>
      </div>
      <div class="line">
        <span>忘记密码</span>
        <span @click="signup">注册账号</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import $cookies from 'vue-cookies'
import { $user } from "@/services";
export default {
  name: "Login",
  data () {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login () {
      if (this.username && this.password) {
        $user
          .login({ userName: this.username, password: this.password })
          .then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "登录成功",
                type: "success",
              });
              $cookies.set('Authorization', res.token, '30MIN')
              $cookies.set('currentUserName', this.username, '30MIN')
              $cookies.set('userInfo', res.info, '30MIN')
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect)
              } else {
                this.$router.push("/map");
              }
            } else {
              this.$message({
                message: "登录失败，请重新输入",
                type: "error",
              });
            }
          });
      } else {
        this.$message({
          message: "请填写用户名和密码",
          type: "warning",
        });
      }
    },
    signup () {
      if(this.$route.query.redirect === 'info' || this.$route.query.redirect === 'uploadData') {
        this.$router.push("/signup?type=2");
      } else {
        this.$router.push("signType");
      }
    },
    resetPassword() {
      this.$router.push("resetPassword");
    }
  },
};
</script>
<style lang="less" scoped>
@cyan: #00baff;
.page {
  background-image: url(../assets/bj-1.jpg);
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  // background-color: #cdd1d3;
  // background-color: #158bb8;
  // background-color: #cdd1d325;

  .box1 {
    height: 10vh;
    position: relative;
    img {
      position: absolute;
      width: 30%;
      bottom: 0;
      left: calc(50% - 15%);
    }
    div {
      width: 100%;
      height: 100%;
      // filter: blur(10px);
      // background-color: #158bb8;
      // background-color: #158ab8;
    }
  }
  .box2 {
    height: 90vh;
    background-color: #cdd1d325;
    position: relative;

    .box3 {
      background-image: url(../assets/back.png);
      position: absolute;
      top: 0;
      width: 100%;
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .from {
        width: 500px;
        background-color: rgba(240, 248, 255, 0.479);
        box-shadow: 0px 2px 6px 0px rgba(181, 181, 181, 0.9);
        border-radius: 3px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        > p {
          text-align: center;
          color: #666;
          font-size: 16px;
          margin-top: 20px;
          width: 100%;
          // text-align: end;
          margin-right: 15px;
          margin-bottom: 20px;
          a {
            color: blue;
            font-size: 18px;
            // border: 1px solid rgba(136, 102, 187, 0.336);
            padding: 3px 6px;
          }
        }
        label {
          display: block;
          width: 25px;
          text-align: center;
          height: 25px;
        }
        .input1 {
          margin-top: 30px;
          margin-bottom: 10px;
          label {
            background-image: url('../assets/1.svg');
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
        .input2 label {
          background-image: url('../assets/2.svg');
          background-size: 20px 20px;
          background-repeat: no-repeat;
          background-position: center center;
        }
        .btn {
          margin-top: 15px;
          background-color: #158bb8;
          justify-content: center;
          border-radius: 3px;
          color: azure;
          font-size: 24px;
          padding: 5px 10px;
        }
        > div {
          display: flex;
          align-items: center;
          background-color: #cdd1d3;
          color: #666;
          border: 1px solid #bcbcbc;
          p {
            width: 1px;
            height: 80%;
            background-color: #464646;
          }

          div {
            height: 35px;
            width: 250px;
            border: 1px solid #ccc;
            border-radius: 2px;
            input {
              border: none;
              outline: 0;
              height: 100%;
              width: 100%;
              box-sizing: border-box;
              padding: 0px 5px;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  // .panel {
  //   width: 464px;
  //   height: 480px;
  //   padding: 50px 32px;
  //   background: #fff;
  //   border-radius: 10px;
  //   margin: 0 20vw 0 auto;
  //   display: flex;
  //   flex-direction: column;
  //   h3 {
  //     color: @cyan;
  //     font-size: 36px;
  //     font-weight: 400;
  //     text-align: center;
  //   }
  //   .line {
  //     &:first-of-type {
  //       margin-top: 50px;
  //     }
  //     margin-top: 30px;
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     label {
  //       font-size: 20px;
  //     }
  //     input {
  //       flex: 1;
  //       margin-left: 32px;
  //       font-size: 18px;
  //       line-height: 40px;
  //       border: 1px solid #130a0a;
  //       border-radius: 0.2em;
  //       padding: 0 0.5em;
  //     }
  //     .btn {
  //       margin: 20px 0;
  //       cursor: pointer;
  //       width: 100%;
  //       line-height: 50px;
  //       text-align: center;
  //       background: @cyan;
  //       color: #fff;
  //       font-size: 30px;
  //       font-weight: 400;
  //       border-radius: 1em;
  //     }
  //     span {
  //       cursor: pointer;
  //       font-size: 20px;
  //       margin: 0 2em;
  //       color: @cyan;
  //     }
  //   }
  // }
}
</style>